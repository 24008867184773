define('ember-href-to/helpers/href-to', ['exports', 'ember', 'ember-getowner-polyfill'], function (exports, _ember, _emberGetownerPolyfill) {
  'use strict';

  exports['default'] = _ember['default'].Helper.extend({
    compute: function compute(params) {
      var router = (0, _emberGetownerPolyfill['default'])(this).lookup('router:main');
      if (router === undefined || router.router === undefined) {
        return;
      }

      var lastParam = params[params.length - 1];

      var queryParams = {};
      if (lastParam && lastParam.isQueryParams) {
        queryParams = params.pop();
      }

      var targetRouteName = params.shift();

      var args = [targetRouteName];
      args.push.apply(args, params);
      args.push({ queryParams: queryParams.values });

      return router.generate.apply(router, args);
    }
  });
});